import React, { useState, useEffect } from 'react';
import { FaSearch, FaEye, FaTimes, FaInfoCircle } from 'react-icons/fa';

interface ElementProps {
  symbol: string;
  name: string;
  details: string;
  category: string;
  skillLevel: string;
  value: number;
}

interface RealElement {
  name: string;
  atomicNumber: number;
  category: string;
  description: string;
  quote: string;
}

const categoryColors: { [key: string]: string } = {
  education: "bg-yellow-100",
  experience: "bg-green-100",
  skills: "bg-blue-100",
  certifications: "bg-purple-100",
  languages: "bg-red-100",
};

const realElements: { [key: string]: RealElement } = {
  "Ar": {
    name: "Argon",
    atomicNumber: 18,
    category: "Noble gas",
    description: "Inert gas used in welding and lighting. Third-most abundant gas in Earth's atmosphere.",
    quote: "The discovery of argon did more than any other single discovery to convince chemists that there were gases 'in the air' that they had overlooked. - Isaac Asimov"
  },
  "Ca": {
    name: "Calcium",
    atomicNumber: 20,
    category: "Alkaline earth metal",
    description: "Essential for living organisms, particularly in cell physiology and bone structure.",
    quote: "Calcium is what gives firmness to our bones and teeth, but it is also necessary for the transmission of nerve impulses and for the contraction of muscles. - Linus Pauling"
  },
  "Pm": {
    name: "Promethium",
    atomicNumber: 61,
    category: "Lanthanide",
    description: "Rare, radioactive element. All its isotopes are radioactive.",
    quote: "Promethium holds the distinction of being the only element whose discovery was predicted before it was found in nature. - Glenn T. Seaborg"
  },
  "Ra": {
    name: "Radium",
    atomicNumber: 88,
    category: "Alkaline earth metal",
    description: "Highly radioactive element, discovered by Marie and Pierre Curie in 1898.",
    quote: "Nothing in life is to be feared, it is only to be understood. Now is the time to understand more, so that we may fear less. - Marie Curie"
  },
  "Th": {
    name: "Thorium",
    atomicNumber: 90,
    category: "Actinide",
    description: "Radioactive element with potential as nuclear fuel.",
    quote: "Thorium is a source of nuclear power. There is enough thorium in the United States alone to power the country for a thousand years. - Carlo Rubbia"
  }
};

const elements: ElementProps[] = [
  { symbol: "MB", name: "MBA", details: "Master of Business Administration - Fitchburg State University (2018)", category: "education", skillLevel: "Degree", value: 5 },
  { symbol: "Bp", name: "B.Pharm", details: "Bachelor of Pharmacy (B.Pharm.), Pharmaceutical Sciences - Massachusetts College of Pharmacy and Health Sciences (2011 - 2015)", category: "education", skillLevel: "Degree", value: 5 },
  { symbol: "Sa", name: "Sanofi", details: "Validation Engineer 3 (September 2022 - Present)", category: "experience", skillLevel: "2 years", value: 2 },
  { symbol: "Ve", name: "Verista", details: "Validation Engineer @ Finch Therapeutics (May 2021 - September 2022)", category: "experience", skillLevel: "1 year 4 months", value: 1 },
  { symbol: "Ca", name: "Catalent", details: "Validation Engineer (December 2020 - May 2021)", category: "experience", skillLevel: "6 months", value: 1 },
  { symbol: "Th", name: "Thermo Fisher", details: "Manufacturing Associate 3 (CDMO) (March 2019 - December 2019)", category: "experience", skillLevel: "10 months", value: 1 },
  { symbol: "Va", name: "Validation", details: "Expert in cGMP and GLP validation, with experience in various laboratory equipment and systems.", category: "skills", skillLevel: "Expert", value: 4 },
  { symbol: "Pm", name: "Project Management", details: "PMP Certified. Skilled in managing multiple qualification projects and stakeholder communications.", category: "skills", skillLevel: "Advanced", value: 3 },
  { symbol: "Ra", name: "Risk Assessment", details: "Proficient in executing gap assessments and 21 CFR Part 11 compliance checks.", category: "skills", skillLevel: "Advanced", value: 3 },
  { symbol: "Qm", name: "Quality Management", details: "Experience with various QMS including MasterControl/Veeva and TrackWise.", category: "skills", skillLevel: "Advanced", value: 3 },
  { symbol: "Da", name: "Data Analysis", details: "Proficient in using DeltaV, PI Historian, and SmartSheets for data monitoring and analysis.", category: "skills", skillLevel: "Intermediate", value: 2 },
  { symbol: "PM", name: "PMP", details: "Project Management Professional (PMP) Certification", category: "certifications", skillLevel: "Certified", value: 4 },
  { symbol: "OE", name: "OE Cert", details: "Operational Excellence Foundations Certification", category: "certifications", skillLevel: "Certified", value: 4 },
  { symbol: "En", name: "English", details: "Native or Bilingual Proficiency", category: "languages", skillLevel: "Native", value: 5 },
  { symbol: "Ar", name: "Arabic", details: "Native or Bilingual Proficiency", category: "languages", skillLevel: "Native", value: 5 },
];

const Legend: React.FC = () => {
  return (
    <div className="flex flex-wrap justify-center items-center space-x-2 bg-white rounded-md shadow px-2 py-1 mt-2 md:mt-0">
      {Object.entries(categoryColors).map(([category, color]) => (
        <div key={category} className="flex items-center m-1">
          <div className={`w-3 h-3 ${color} border border-gray-300 mr-1`}></div>
          <span className="text-xs">{category}</span>
        </div>
      ))}
    </div>
  );
};

const Element: React.FC<ElementProps & { onClick: (element: ElementProps) => void }> = ({ symbol, name, details, category, skillLevel, value, onClick }) => {
  const isRealElement = symbol in realElements;
  
  return (
    <div 
      className={`p-2 border-2 border-gray-300 aspect-square ${categoryColors[category]} hover:shadow-lg transition-all duration-300 ease-in-out flex flex-col justify-between cursor-pointer transform hover:scale-105 relative`}
      onClick={() => onClick({ symbol, name, details, category, skillLevel, value })}
    >
      <div className="flex justify-between items-start">
        <span className="text-xs">{category}</span>
        <span className="text-sm font-semibold">{value}</span>
      </div>
      <div className="text-center">
        <div className="text-2xl md:text-3xl font-bold">{symbol}</div>
        <div className="text-xs md:text-sm font-semibold truncate">{name}</div>
      </div>
      <div className="text-xs text-center">{skillLevel}</div>
      {isRealElement && <FaInfoCircle className="absolute bottom-1 right-1 text-blue-500" size={12} />}
    </div>
  );
};

const ElementAnimation: React.FC<{ element: ElementProps }> = ({ element }) => {
  const realElement = realElements[element.symbol as keyof typeof realElements];
  if (!realElement) return null;

  const atomicNumber = realElement.atomicNumber;
  const electronShells = [2, 8, 18, 32, 32, 18, 8];

  let remainingElectrons = atomicNumber;
  const filledShells = electronShells.map(shellCapacity => {
    if (remainingElectrons >= shellCapacity) {
      remainingElectrons -= shellCapacity;
      return shellCapacity;
    }
    const partiallyFilled = remainingElectrons;
    remainingElectrons = 0;
    return partiallyFilled;
  }).filter(electrons => electrons > 0);

  return (
    <svg className="w-32 h-32 mx-auto my-4" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="5" fill="#ff4136" /> {/* Nucleus */}
      {filledShells.map((electrons, index) => (
        <g key={index} className="animate-spin" style={{ animationDuration: `${(index + 2) * 5}s` }}>
          <circle cx="50" cy="50" r={10 + index * 10} fill="none" stroke="#7fdbff" strokeWidth="0.5" />
          {Array.from({ length: electrons }).map((_, i) => (
            <circle
              key={i}
              cx="50"
              cy={50 - (10 + index * 10)}
              r="2"
              fill="#0074d9"
              transform={`rotate(${(i * 360) / electrons}, 50, 50)`}
            />
          ))}
        </g>
      ))}
    </svg>
  );
};

const DetailView: React.FC<{ element: ElementProps | null, onClose: () => void }> = ({ element, onClose }) => {
  if (!element) return null;
  const realElement = realElements[element.symbol as keyof typeof realElements];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-4 md:p-6 max-w-2xl w-full max-h-[90vh] overflow-auto relative">
        <h2 className="text-xl md:text-2xl font-bold text-blue-600">{element.name} <span className="text-gray-500">({element.symbol})</span></h2>
        <p className="text-gray-600 mb-2">{element.category}</p>
        <p className="text-blue-600 mb-4">Skill Level: {element.skillLevel}</p>
        <p className="mb-4">{element.details}</p>
        
        {realElement && (
          <div className="bg-blue-50 p-4 rounded-lg mt-4">
            <h3 className="text-lg md:text-xl font-semibold text-blue-700 mb-2">Element Info: {realElement.name}</h3>
            <p><strong>Atomic Number:</strong> {realElement.atomicNumber}</p>
            <p><strong>Category:</strong> {realElement.category}</p>
            <p className="mt-2">{realElement.description}</p>
            <p className="mt-2 italic">{realElement.quote}</p>
            <ElementAnimation element={element} />
          </div>
        )}
        
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FaTimes size={24} />
        </button>
      </div>
    </div>
  );
};

export default function PeriodicTableResume() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedElement, setSelectedElement] = useState<ElementProps | null>(null);
  const [showPDF, setShowPDF] = useState(false);
  const [filteredElements, setFilteredElements] = useState(elements);

  useEffect(() => {
    setFilteredElements(
      elements.filter(element =>
        element.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        element.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  return (
    <div className="periodic-table-resume bg-gray-100 min-h-screen p-4 md:p-8">
      <header className="mb-6 md:mb-8">
        <h1 className="text-3xl md:text-4xl font-bold mb-2 text-blue-600">Hussam Aloch, MBA, PMP</h1>
        <p className="text-sm md:text-base text-gray-600">Upton, MA | 508-826-0663 | hussamaloch@email.com | LinkedIn: hussamaloch</p>
      </header>

      <div className="flex flex-col md:flex-row items-center justify-between mb-6 space-y-4 md:space-y-0">
        <div className="w-full md:w-auto flex-grow flex items-center bg-white rounded-md shadow mr-0 md:mr-4">
          <input
            type="text"
            placeholder="Search skills, experiences..."
            className="border-none rounded-l-md p-2 w-full md:w-64 focus:outline-none flex-grow"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="text-gray-500 mr-2" />
        </div>

        <Legend />

        <button onClick={() => setShowPDF(true)} className="w-full md:w-auto bg-blue-500 text-white py-2 px-4 rounded-md flex items-center justify-center hover:bg-blue-600 transition-colors duration-200 ease-in-out text-sm whitespace-nowrap">
          <FaEye className="mr-2" /> View PDF
        </button>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-4 relative z-10">
        {filteredElements.map((element, index) => (
          <Element
            key={index}
            {...element}
            onClick={setSelectedElement}
          />
        ))}
      </div>

      {selectedElement && <DetailView element={selectedElement} onClose={() => setSelectedElement(null)} />}

      {showPDF && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-4 w-full max-w-4xl h-5/6 relative">
            <button 
              onClick={() => setShowPDF(false)} 
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
            >
              <FaTimes size={24} />
            </button>
            <iframe
              src="/hussam_resume.pdf"
              title="Resume PDF"
              className="w-full h-full"
            />
          </div>
        </div>
      )}
    </div>
  );
}
